/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
import { isEmpty } from './validators';
import { CONTEXT } from 'utils/Constants/Content';
import { useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment-timezone';
import { DATE_FORMAT } from 'utils/Constants';

export function capitalizeFirstLetter(str: string): string {
  if (str !== '' && str !== undefined && str !== null) {
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalized;
  }
  return str;
}

export const getFilterName = (value: string): string => {
  if (value !== '') {
    const capitalized = capitalizeFirstLetter(value);
    if (capitalized === 'Resolved') {
      return capitalized + ' Events';
    } else {
      return capitalized + ' view';
    }
  }
  return value;
};
export function sleep(delay = 0): any {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}
export function orderby(list: any[], type: any): any {
  try {
    let result: any = [];
    if (list.length > 0) {
      const filters = list.filter(c => c[type] !== null);
      const nullRecord = list.filter(c => c[type] === null);
      const orderList = sortBy(filters, type);
      result = [...orderList, nullRecord];
      return result;
    }
  } catch (error) {
    return list;
  }
}

export function sortBy(list: any[], type: any): any {
  try {
    let orderList: any = [];
    if (list.length > 0) {
      const filters = list.filter(c => c[type] !== null);
      orderList = filters.sort((a: string, b: any) =>
        a[type].localeCompare(b[type]),
      );
      return orderList;
    } else {
      return [];
    }
  } catch (error) {
    return list;
  }
}

export const checkIEVersion = (): any => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('Trident/7.0');
  if (msie > 0) {
    return true;
  }
  return false;
};
export const removeDuplicates = (data: any): any => {
  if (data) {
    const filterData = data.filter((c: any) => c !== null);
    try {
      return filterData.filter(
        (c: any, index: any) => filterData.indexOf(c) === index,
      );
    } catch (error) {
      return data;
    }
  }
};

export const updateState = (
  records: any,
  type: string,
  key: any,
  values: any,
): any => {
  if (records && records.length && values && values.length) {
    try {
      const list = JSON.parse(JSON.stringify(records));
      list.map((item: any) => {
        if (values.includes(item[key])) {
          item.isSelected = type === 'add';
        }
      });
      return list;
    } catch (error) {
      return records;
    }
  } else {
    return records;
  }
};
export const removeFromObj = (records: any, key: any, values: any): any => {
  if (records && records.length && values && values.length) {
    try {
      return records.filter((c: any) => !values.includes(c[key]));
    } catch (error) {
      return records;
    }
  } else {
    return records;
  }
};
export const filterByValue = (
  array: any,
  string: string,
  searchKey: string,
): any => {
  try {
    const stringText = string.replace(/\s/g, '').toLowerCase();
    return array.filter((o: any) =>
      o[searchKey]
        .replace(/\s/g, '')
        .toLowerCase()
        .includes(stringText.toLowerCase()),
    );
  } catch (error) {
    return array;
  }
};
export const filterByTypenValue = (
  array: any,
  string: string,
  searchKey: string,
  typeSearchKey: string,
  typeString: string,
): any => {
  try {
    const stringText = string.replace(/\s/g, '').toLowerCase();
    return array.filter(
      (o: any) =>
        o[searchKey]
          .replace(/\s/g, '')
          .toLowerCase()
          .includes(stringText.toLowerCase()) &&
        o[typeSearchKey] === typeString,
    );
  } catch (error) {
    return array;
  }
};
export const filterById = (records: any, values: any, key: string): any => {
  if (records && records.length && values && values.length) {
    try {
      return records.filter((c: any) => values.includes(c[key]));
    } catch (error) {
      return records;
    }
  } else {
    return records;
  }
};

export const manageDropdownDataForIE = (data: any): any[] => {
  let dataArray: any = [];
  if (data?.length > 0) {
    dataArray = data.map((obj: any) => {
      return { label: obj.key, value: obj.value };
    });
  }
  return dataArray;
};
export const navigateToExternalUrl = (url: string): void => {
  if (!isEmpty(url)) {
    const isDownloadble = url.endsWith('.exe') || url.endsWith('.zip');
    if (isDownloadble) {
      const link = document.createElement('a');
      link.download = 'Download';
      link.href = url;
      link.click();
    } else {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  }
};

export const useDebounce = (value: any, delay: any): any => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
export const convertTZ = (date: any): any => {
  const ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: ZONE,
    }),
  );
};
export const printPage = (elementId: any, Iframe: any): any => {
  try {
    const content: any = document.getElementById(elementId);
    const odd: any = document.getElementById(Iframe);
    let contentPrint;
    if (odd !== null) {
      contentPrint = odd.contentWindow;
    } else {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('title', Iframe);
      iframe.setAttribute('id', Iframe);
      iframe.setAttribute(
        'style',
        'height: 0px; width: 0px; position: absolute; display:none;',
      );
      document.body.appendChild(iframe);
      contentPrint = iframe.contentWindow;
    }
    contentPrint.document.open();
    contentPrint.document.write(content.innerHTML);
    contentPrint.document.close();
    contentPrint.focus();
    contentPrint.print();
  } catch (error) {
    console.log(error);
  }
};
export const customListSearch = (sourceList: any, allList: any): any => {
  try {
    if (allList && allList.length > 0) {
      return sourceList.map((val: any) => {
        const value = val?.label ? val.label : val.key ? val.key : val;
        const obj = allList.find((o: any) => o.key === value);
        return obj.value;
      });
    }
  } catch (error) {
    console.log(error);
  }
};
export const getFilterData = (obj: any): any => {
  const data: any = JSON.parse(JSON.stringify(obj));
  delete data.isFilterApplied;
  return data;
};
export const getFormatedPhone = (value: any): any => {
  try {
    if (value) {
      let number = value.trim().replace(/[^0-9]/g, '');
      if (number.length < 7) {
        number = number.replace(/(\d{3})(\d{1})/, '($1) $2');
      } else if (number.length < 10) {
        number = number.replace(/(\d{3})(\d{3})(\d{1})/, '($1) $2-$3');
      } else {
        number = number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }
      return number;
    } else {
      return value;
    }
  } catch (error) {
    console.log(error);
  }
};
export const getTenDigitNumber = (value: string) => {
  try {
    if (!isEmpty(value)) {
      return value.replace(/[/ /()-]/g, '');
    } else {
      return value;
    }
  } catch (error) {
    return value;
  }
};
export const validNorthAmericanNumber = (value: string) => {
  try {
    const num = getTenDigitNumber(value);
    if (num) {
      if (
        parseInt(num.substring(0, 3)) <= 200 ||
        parseInt(num.substring(3, 6)) <= 199 ||
        num.substring(4, 6) === '11'
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  } catch (error) {
    return false;
  }
};
export const hideString = (string: any) => {
  let encrypt = '';
  for (let index = 0; index < string.length; index++) {
    encrypt += '*';
  }
  return encrypt;
};
export const getDefaultParams = (): any => {
  return {
    page: CONTEXT.DEFAULT_PAGE,
    limit: CONTEXT.DEFAULT_PAGE_LIMIT,
    recordCountRequire: CONTEXT.DEFAULT_PAGE_COUNT_REQUIRED,
  };
};

export const useMobileDevice = (): any => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};
export const checkCustomHeights = (isMobile: boolean): any => {
  return isMobile ? 80 : 135;
};

export const getSelectedID = (it: any): any => {
  try {
    if (it && it.length > 0) {
      return it.map((x: any) => x.value);
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
};
export const loadDynamicScript = (
  FILE_URL: any,
  async = true,
  type = 'text/javascript',
): any => {
  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement('script');
      script.type = type;
      script.async = async;
      script.src = FILE_URL;

      script.addEventListener('load', ev => {
        resolve({ message: '' });
      });

      script.addEventListener('error', ev => {
        const err = new Error();
        err.message = `Failed to load the script ${FILE_URL}`;
        reject(err);
      });

      document.head.appendChild(script);
    } catch (error) {
      reject(error);
    }
  });
};

export const getFormatedDate = (
  date: any,
  format: string = DATE_FORMAT,
  zone: string = '',
  type: string = '',
): any => {
  try {
    if (isEmpty(date)) {
      return '-';
    } else if (!isEmpty(type)) {
      switch (type) {
        case 'utc':
          return moment.utc(date).format(format);
        case 'unix':
          return moment.unix(date).format(format);
        default:
          return moment(date).format(format);
      }
    }
    return !isEmpty(zone)
      ? moment(date).tz(zone).format(format)
      : moment(date).format(format);
  } catch (error) {
    return date;
  }
};
export const getFormatedTime = (
  time: any,
  format: any,
  timeformat: any,
): any => {
  try {
    if (isEmpty(time)) {
      return '-';
    } else {
      return moment(time, format).format(timeformat);
    }
  } catch (error) {
    return time;
  }
};
export const Logger = (message: any): void => {
  if (process.env.REACT_APP_ENV === 'development') {
    console.log(message);
  }
};
export const Regex = (value: string, flags = 'g') => {
  try {
    return new RegExp(value, flags);
  } catch (error) {
    return value;
  }
};
export const removeChar = (str: any, value: any): any => {
  try {
    if (!isEmpty(str) && !isEmpty(value)) {
      return str.replaceAll(value, '');
    }
  } catch (error) {
    return str;
  }
};
export const formatBytes = (value: any, decimal = 2) => {
  try {
    if (value === 0) return '0 Bytes';
    const k = 1024;
    const sizes = [
      `${value <= 1 ? 'Byte' : 'Bytes'}`,
      'KB',
      'MB',
      'GB',
      'TB',
      'PB',
      'EB',
      'ZB',
      'YB',
    ];
    const i = Math.floor(Math.log(value) / Math.log(k));
    return `${parseFloat((value / Math.pow(k, i)).toFixed(decimal))} ${
      sizes[i]
    }`;
  } catch (error) {
    return value;
  }
};
export const arthOperation = (a: number, b: number, type = 'add'): any => {
  switch (type) {
    case 'add':
      return a + b;
    case 'sub':
      return a - b;
  }
};
export const removeLineBreaks = (text: any) => {
  try {
    if (!isEmpty(text)) return text.replace(/(\r\n|\n|\r)/gm, '');
    return text;
  } catch (error) {
    return text;
  }
};
export const filterByTextMatch = (
  array: any,
  string: string,
  searchKey: string,
): any => {
  try {
    const stringText = string.replace(/\s/g, '').toLowerCase();
    return array.filter(
      (o: any) =>
        o[searchKey].replace(/\s/g, '').toLowerCase() ===
        stringText.toLowerCase(),
    );
  } catch (error) {
    return array;
  }
};
export const convertArrayToText = (value: any = []) => {
  try {
    if (value.length > 0) return value.toString().replace(',', '/');
    return value;
  } catch (error) {
    return value;
  }
};
